import React from "react"
import PropTypes from "prop-types"

import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Fade } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import Button from "../components/button"
import desktop1 from "../images/desktop-1.png"
import desktop2 from "../images/desktop-2.png"
import desktop3 from "../images/desktop-3.png"

const heroImages = [
  {
  url: desktop1,
  caption: 'Browser screenshot'
  },
  {
  url: desktop2,
  caption: 'Browser screenshot'
  },
  {
  url: desktop3,
  caption: 'Browser screenshot'
  },
];

const Header = ({ siteTitle }) => {
  const breakpoints = useBreakpoint();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "8rem 1rem 0 1rem",
      }}
    >
      <h1 style={{ textAlign: "center" }}>The dictionary of everyday French.</h1>
      <p style={{ textAlign: "center", maxWidth: 440 }}>
        Discover contemporary slang and handy expressions, with vocabulary powered by people.
      </p>
      <Button>SIGN IN</Button>
      {!breakpoints.sm && (
        <div
          style={{
            margin: 60,
            position: "relative",
            width: "90vw",
            maxWidth: "1440px",
            top: 0,
          }}
        >
          {/* <img
            src={desktop13}
            alt="screenshot of app"
          /> */}
          <Fade
            arrows={false}
            duration={5000}
            transitionDuration={500}
            pauseOnHover={false}
            canSwipe={false}
          >
            {heroImages.map((heroImage, index) => (
              <div className="each-fade" key={index}>
                <div className="image-container">
                  <img src={heroImage.url} />
                </div>
              </div>
            ))}
          </Fade>
        </div>
      )}
      {/* <div style={{ margin: 60, width: `250px`, position: "relative" }}>
        <div style={{ clipPath: "inset(2% 5% round 2% 5%)" }}>
          <MockupContent />
        </div>
        <div
          style={{
            position: "absolute",
            width: "250px",
            top: 0,
          }}
        >
          <img
            src={mockupFrame}
            alt="outlines of shapes and confetti in the background "
          />
        </div>
      </div> */}
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
