import React from "react"

import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import ReactPlayer from 'react-player/lazy'
import SectionHeader from "./section-header"
import ios from "../images/iOS.mp4"
import iosFallback from "../images/iOS-fallback.jpg"
import mockupFrame from "../images/mockup-frame.png"

const Content = () => {
  const breakpoints = useBreakpoint();
  return (
    <div
      // style={{ padding: "4rem 1rem", textAlign: "center" }}
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "0 1rem 4rem 1rem",
      }}
    >
      {!breakpoints.sm && (
        <SectionHeader
          title="Mobile-friendly."
          description="Sign in and continue browsing on the go."
        />
      )}
      <div style={{ margin: 60, width: "250px", position: "relative" }}>
        <div style={{ display: "flex", clipPath: "inset(2% 5% round 2% 5%)" }}>
          <ReactPlayer
            url={ios}
            playing
            loop={true}
            controls={false}
            muted
            playsinline
            fallback={iosFallback}
            height="475px"
            width="250px"
            style={{marginTop: "16px"}}
          />
          {/* <img
            src={iosFallback}
            width="220px"
            alt="screenshot of app"
            style={{alignItems: "center", justifyContent: "center"}}
          /> */}
        </div>
        <div
          style={{
            position: "absolute",
            width: "250px",
            top: 0,
          }}
        >
          <img
            src={mockupFrame}
            alt="outlines of shapes and confetti in the background "
          />
        </div>
      </div>
      {/* <content
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
        }}
      >
        Test
      </content> */}
    </div>
  );
};

export default Content
