import React from "react"

import Button from "../components/button"
import SectionHeader from "./section-header"

const CallToAction = () => (
  <div style={{ padding: "4rem 1rem 4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Try it out!"
      description="Create an account to start discovering argots."
    />
    <Button>SIGN IN</Button>
  </div>
)

export default CallToAction
