import React from "react"

import { COLORS, BORDER_RADIUS } from "../styles/constants"
import "../styles/button.css"

const launchApp = () => {
  window.location.href = 'https://app.argotique.net';
}

const Button = ({ children }) => (
  <button
    style={{
      padding: ".5rem 2.5rem",
      color: COLORS.lightWhite,
      fontWeight: 700,
      fontSize: "smaller",
      background: COLORS.blue,
      borderRadius: BORDER_RADIUS,
      borderWidth: 0,
      cursor: "pointer",
    }}
    onClick={launchApp}
  >
    {children}
  </button>
)

export default Button
